import React, { Component } from 'react';
import {
    Drawer,
    TextField,
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress
} from '@material-ui/core/';

import PubSub from 'pubsub-js'
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import ReviewIcon from '@material-ui/icons/Visibility';
import ValidIcon from '@material-ui/icons/CheckCircle';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import ObservationTypeView from './ObservationTypeView';
import ReviewObservations from './ReviewObservations';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';

const Promise = require('bluebird');

export class ObservationTypesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dependencies: true,
            notReviewed: false,
            invalid: false,
            dialog: null,
            filter: '',
            rules: [],
            filtered: null,
            deleteRules: [],
            error: null,
            progress: null
        }

    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
    }

    componentDidUpdate() {
        this.handleOpen();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext } = this.props;
        const { plan } = appContext.state;
        if (plan) {
            const { planId } = plan;
            const { dependencies, notReviewed, invalid } = this.state;

            const store = appContext.stores.DataTypesStore;
            const rules = store.getObservationRules().filter((e) => (dependencies === true || e.planId === planId)
                && (notReviewed === false || !e.isReviewed)
                && (invalid === false || (!e.system || !e.code || !e.name)));

            this.setState({ rules }, this.updateFilter.bind(this));
        }
    }

    updateFilter() {
        function testFilter(filter, observation) {
            if (observation.ruleId && observation.ruleId.toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                return true;
            if (observation.name && observation.name.toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                return true;
            if (observation.dataId && observation.dataId.toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                return true;
            if (observation.code && observation.code.toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                return true;
            if (observation.system && observation.system.toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                return true;
            if (isArrayValid(observation.tags)) {
                for (var i = 0; i < observation.tags.length; ++i) {
                    if (observation.tags[i].toLowerCase().indexOf(String(filter).toLowerCase()) >= 0)
                        return true;
                }
            }

            return false;
        }

        const { filter, rules } = this.state;
        if (filter) {
            let filtered = [];
            for (let i = 0; i < rules.length; ++i) {
                const rule = rules[i];
                if (testFilter(filter, rule)) {
                    filtered.push(rule);
                }
            }

            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }


    handleOpen() {
        // automatically open the question by ID if provided..
        let parts = window.location.pathname.slice(1).split('/');
        if (parts.length > 1) {
            window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.lastIndexOf('/')));

            let rule = this.state.rules.find((e) => e.ruleId === parts[1]);
            if (rule)
                this.onEditRule(rule);
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        this.loadContent();
        if (this._types)
            this._types.setState({ selected: [] });
    }

    onEditSelectedRule(table) {
        const { selected, data } = table.state;
        if (selected.length > 0) {
            const rule = data[selected[0]];
            this.onEditRule(rule);
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onEditRule(rule) {
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <ObservationTypeView appContext={this.props.appContext} rule={rule} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    createRule() {
        console.log("createRule");
        let newRule = {
            normalizationMethod: 'Linear',
            name: '',
            ruleId: '',
            dataId: '',
            system: '',
            code: '',
            unit: '',
            tupleIndex: 0,
            normalizedTupleIndex: 1,
            unitTupleIndex: 2,
            targetUnit: ''
        };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <ObservationTypeView appContext={this.props.appContext} rule={newRule} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    onDeleteRule(type) {
        console.log("onDeleteType:", type);
        const { deleteRules } = this.state;
        deleteRules.push(type);

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Please confirm the deletion of {deleteRules.length} rules?</DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    this.setState({ dialog: null, deleteRules: [] });
                }}>Cancel</Button>
                <Button onClick={() => {
                    const { appContext } = this.props;

                    this.setState({ dialog: null, progress: <CircularProgress size={20} />, error: null, deleteRules: [] });
                    Promise.map(deleteRules, (type) => {
                        return appContext.stores.DataTypesStore.deleteObservationRule(type.ruleId, type.planId);
                    }, { concurrency: 20 }).then(() => {
                        this.loadContent();
                    }).catch((err) => {
                        console.error("Caught error:", err );
                        this.setState({ progress: null, error: getErrorMessage(err) });
                    })
                }} >Confirm</Button>
            </DialogActions>
        </Dialog>;
        this.setState({ dialog });
    }

    onReviewSelected(table) {
        const { selected, data } = table.state;
        let rules = [];
        selected.forEach(element => {
            rules.push(data[element])
        });
        this.setState({ dialog: <ReviewObservations appContext={this.props.appContext} rules={rules} onClose={this.onCloseDialog.bind(this)} /> });
    }

    render() {
        const { dialog, dependencies, notReviewed, rules, filter, filtered, error, invalid } = this.state;
        const store = this.props.appContext.stores.DataTypesStore;
        let errors = store.getErrors('observations').errors;

        const columnData = [
            {
                id: 'ruleId', width: 10, label: 'Valid', formatValue: (v) => {
                    return errors[v] ? <Tooltip key='error' title={errors[v].join(', ')}><WarningIcon style={{ fill: 'red' }} /></Tooltip> : <ValidIcon key='valid' style={{ fill: 'green' }} />;
                }
            },
            { id: 'isReviewed', width: 50, label: 'Reviewed', formatValue: (v) => toBoolean(v) ? "Yes" : "No" },
            { id: 'isIgnored', width: 50, label: 'Ignored', formatValue: (v) => toBoolean(v) ? "Yes" : "No" },
            { id: 'name', width: 300, label: 'Name', formatValue: (v) => v.substring(0,32) },
            { id: 'system', width: 300, label: 'System', formatValue: (v) => v.substring(0,64) },
            { id: 'code', width: 150, disablePadding: false, label: 'Code', formatValue: (v) => v.substring(0,12) },
            //{ id: 'unit', width: 50, label: 'Unit' },
            { id: 'dataId', width: 100, label: 'Data ID' },
            { id: 'targetUnit', width: 100, label: 'Target Unit' },
            {
                id: 'planId', width: 200, label: 'Plan ID', formatValue: (v) => {
                    return <Plan appContext={this.props.appContext} planId={v} />
                }
            }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td>
                        <TextField style={styles.text} value={filter} label="Filter"
                            onChange={(e) => { this.setState({ filter: e.target.value }, this.updateFilter.bind(this)) }} />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={dependencies} onChange={(e, v) => {
                            this.setState({ dependencies: v }, this.loadContent.bind(this));
                        }} />} label="Show Dependencies" />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={invalid} onChange={(e, v) => {
                            this.setState({ invalid: v }, this.loadContent.bind(this));
                        }} />} label="Show Invalid" />
                        <FormControlLabel style={{ margin: 5 }} control={<Checkbox checked={notReviewed} onChange={(e, v) => {
                            this.setState({ notReviewed: v }, this.loadContent.bind(this));
                        }} />} label="Not Reviewed" />
                    </td>
                    <td align="right">
                        {error}
                        <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>
                            { store.progress || <RefreshIcon /> }
                    </IconButton>                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            disableMultiSelect={false}
                            onActions={(table, numSelected, actions) => {
                                if (numSelected > 0) {
                                    actions.unshift(<Tooltip title='Review' key='review'><IconButton onClick={this.onReviewSelected.bind(this, table)}><ReviewIcon /></IconButton></Tooltip>);
                                }
                                if (numSelected === 1) {
                                    actions.unshift(<Tooltip title='Edit' key='edit'><IconButton onClick={this.onEditSelectedRule.bind(this, table)}><CreateIcon /></IconButton></Tooltip>);
                                }
                            }}
                            onTable={table => this._types = table}
                            onAdd={this.createRule.bind(this)}
                            onDelete={this.onDeleteRule.bind(this)}
                            orderBy='name'
                            columnData={columnData}
                            data={filtered ? filtered : rules}
                            title='Observation Rules' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

const styles = {
    text: {
        margin: 5,
        width: 500
    },
}

export default ObservationTypesView;
