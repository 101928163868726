import React from 'react';
import PubSub from 'pubsub-js'

import {
    loadTypes,
    loadQuestions,
    loadText,
    loadModels,
    loadDetectModels,
    isArrayValid,
    loadDataModels,
    loadRecommendationModels,
    loadRecommendations,
    loadObservations,
    loadPredictorModels,
    loadDataTypes,
    saveDetectModels,
    saveModels,
    saveQuestions,
    saveDataModel,
    saveRecommendtionModel,
    saveObservation,
    savePredictorModel,
    saveDataType,
    saveText,
    deleteText,
    loadEducation,
    deleteQuestion,
    translateText,
    loadAlertTypes,
    getAlertLevels,
    deleteDataType,
    loadMedications,
    loadConditions,
    loadProcedures,
    saveMedication,
    deleteMedication,
    saveProcedure,
    deleteProcedure,
    saveCondition,
    deleteCondition,
    deleteModels,
    deleteObservation
} from '@apricityhealth/web-common-lib/utils/Services';

import { parseCode } from '@apricityhealth/web-common-lib/utils/ParseCode';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import { CircularProgress } from '@material-ui/core';

const log = new Logger();

//! This store loads up all the data types, questions, models, etc and can enumerate the relationships between all the data and validate the data for errors
//! TODO: It would be nice to add locking, unlocking, and some method of only pulling new data from the back-end when data is changed.
export class DataTypesStore {
    data = { dataIds: {}, textIds: {}, cache: {}, cacheReady: false };
    progress = null;

    constructor(appContext, root) {
        this.appContext = appContext
        this.root = root
    }

    //#region Accessors

    // Get the internal database of all the types
    getCache() {
        return this.data.cache;
    }

    getQuestions() {
        return this.data.cache.questions || [];
    }

    getModels() {
        return this.data.cache.models || [];
    }

    getDetectModels() {
        return this.data.cache.detect || [];
    }

    getDataModels() {
        return this.data.cache.data || [];
    }

    getRecommendModels() {
        return this.data.cache.recommend || [];
    }

    getRecommendCategories() {
        return this.data.cache.recommend_categories || [];
    }

    getRecommendationTypes() {
        return this.data.cache.recommend_types || [];
    }

    getRecommendGroupTypes() {
        return this.data.cache.recommend_group_types || [];
    }

    getRecommendProtocols() {
        return this.data.cache.recommend_protocols || [];
    }

    getObservationRules() {
        return this.data.cache.observations || [];
    }

    getPredictorModels() {
        return this.data.cache.predictors || [];
    }

    getTexts() {
        return this.data.cache.texts || [];
    }

    getTextHash() {
        return this.data.texts || {};
    }

    getDataTypes() {
        return this.data.cache.types || [];
    }

    getEducation() {
        return this.data.cache.education || [];
    }

    getAlertLevels() {
        return this.data.cache.alert_levels || [];
    }

    getAlertTypes() {
        return this.data.cache.alert_types || [];
    }

    getMedications() {
        return this.data.cache.medications || [];
    }

    getMedicationsHash() {
        return this.data.cache.medicationsHash || {};
    }

    getConditions() {
        return this.data.cache.conditions || [];
    }

    getConditionsHash() {
        return this.data.cache.conditionsHash || {};
    }

    getProcedures() {
        return this.data.cache.procedures || [];
    }

    getProceduresHash() {
        return this.data.cache.proceduresHash || {};
    }

    // Get the localized string for the given textId
    getText(textId, language = 'en-us') {
        if (this.data.texts) {
            if (this.data.texts[language]) {
                let text = this.data.texts[language][textId];
                if (text) return text.text;
            }
        }
        return `[[${textId}]]`;
    }

    //! this returns an array of questionIds that can provide the given dataId
    findQuestionIdsForDataId(dataId) {
        let questionIds = [];
        let questions = this.getQuestions();
        for (let i = 0; i < questions.length; ++i) {
            let question = questions[i];
            if (question.dataId === dataId || question.answers.find((e) => e.dataId === dataId)) {
                questionIds.push(question.questionId);
            }
        }
        log.debug(`findQuestionIdsForDataId ${dataId}:`, questionIds);
        return questionIds;
    }

    // This returns an array of questionIds that could be triggered by a given dataId. 
    findRelatedQuestionIdsForDataId(dataId) {
        const self = this;
        let questionIds = [];

        function checkSymptoms(symptoms) {
            if (isArrayValid(symptoms)) {
                for (let i = 0; i < symptoms.length; ++i) {
                    let { dataId } = symptoms[i];
                    if (dataId)
                        questionIds.push(...self.findQuestionIdsForDataId(dataId));
                }
            }
        }

        const models = this.getModels();
        for (let i = 0; i < models.length; ++i) {
            let model = models[i];
            if (!isArrayValid(model.triggerSymptoms)) continue;
            let triggers = model.triggerSymptoms.filter((e) => e.dataId === dataId);
            if (triggers.length <= 0) continue;       // this model has no triggers for this dataId

            checkSymptoms(model.primarySymptoms);
            checkSymptoms(model.associatedSymptoms);
            checkSymptoms(model.positiveSymptoms);
            checkSymptoms(model.negativeSymptoms);
            checkSymptoms(model.clinicalModifiers);
        }
        // remove any duplicate questionIds
        questionIds = questionIds.filter((v, i, a) => a.indexOf(v) === i);
        log.debug(`findRelatedQuestionIdsForDataId ${dataId}:`, questionIds);
        return questionIds;
    }
    //#endregion

    //#region Public Interface

    // Start up the internal timer of this store and it will update it's internal DB automatically based on the configured interval
    startUpdates(updateInterval = 30, enableValidate = true) {
        if ( this.timer ) {
            clearInterval(this.timer);
            this.timer = null;
        }
        this.enableValidate = enableValidate;
        if ( updateInterval > 0 ) {
            this.timer = setInterval(this.updateDataTypes.bind(this), updateInterval * 1000);
        }

        // lastly, force an update now of the data types..
        this.updateDataTypes();
    }

    // Force an update of the internal DB now 
    updateDataTypes() {
        const { plan } = this.appContext.state;
        if (this.timer && plan && plan.planId) {
            this.loadDataTypes().then(() => {
                log.debug("DataTypesStore updated.");
            }).catch((err) => {
                console.error("Failed to update data link cache:", err);
            });
        }
    }

    // Stop the internal update timer
    stopUpdates() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    // Get all errors for a given category, e.g. getErrors('questions')
    getErrors(category) {
        if (this.data.errors) {
            let errors = this.data.errors[category];
            if (errors !== undefined) return errors;
        }
        return { count: 0, errors: {} };
    }


    getTextLinks(textId) {
        if (textId === undefined) return { links: [] };
        if (this.data.textIds[textId] !== undefined) {
            return this.data.textIds[textId];
        }
        else if (this.data.cacheReady) {
            let cache = this.data.cache;

            let links = [];
            cache.questions.forEach(question => this.addQuestionTextLinks(question, textId, links));

            let data = { links };
            this.data.textIds[textId] = data;
            return data;
        }
        else {
            console.warn("cache not ready!");
            return { links: [] };
        }
    }

    // Get all links to the given dataId, e.g. getDataTypeLinks('a1c')
    getDataTypeLinks(dataId) {
        if (dataId === undefined) return { links: [] };

        if (Array.isArray(dataId)) {
            let results = [];
            for (let i = 0; i < dataId.length; ++i) {
                results.push(this.getDataTypeLinks(dataId[i]));
            }
            return results;
        }

        if (this.data.dataIds[dataId] !== undefined) {
            return this.data.dataIds[dataId];
        }
        else if (this.data.cacheReady) {
            let cache = this.data.cache;

            let links = [];
            cache.questions.forEach(question => this.addQuestionLinks(question, dataId, links));
            cache.models.forEach(model => this.addModelLinks(model, dataId, links));
            cache.detect.forEach(detect => this.addDetectLinks(detect, dataId, links));
            cache.data.forEach(data => this.addDataModelLinks(data, dataId, links));
            cache.recommend.forEach(recommend => this.addRecommendLinks(recommend, dataId, links));
            cache.observations.forEach(observation => this.addObservationLinks(observation, dataId, links));
            cache.predictors.forEach(predictor => this.addPredictorLinks(predictor, dataId, links));
            cache.types.forEach(type => this.addDataTypeLinks(type, dataId, links));
            cache.education.forEach(education => this.addEducationLinks(education, dataId, links));

            let data = { dataId, links };
            this.data.dataIds[dataId] = data;
            return data;
        }
        else {
            console.warn("cache not ready!");
            return null;
        }
    }

    // Helper function to update any tuple indexes used by any conditions for a given dataId
    // tupleUpdates should be an array, for example: [ { oldIndex: 5, newIndex: 6 }, { oldIndex: 6, newIndex: 5 } ];
    updateTupleIndexes(dataId, tupleUpdates) {
        let typeLinks = this.getDataTypeLinks(dataId);
        for (let i = 0; i < typeLinks.links.length; ++i) {
            let link = typeLinks.links[i];
            if (link.detect !== undefined) {
                this.updateDetectTuples(link.detect, dataId, tupleUpdates);
            } else if (link.model !== undefined) {
                this.updateModelTuples(link.model, dataId, tupleUpdates);
            } else if (link.question !== undefined) {
                this.updateQuestionTuples(link.question, dataId, tupleUpdates);
            } else if (link.data !== undefined) {
                this.updateDataModelTuples(link.data, dataId, tupleUpdates);
            } else if (link.recommend !== undefined) {
                this.updateRecommendTuples(link.recommend, dataId, tupleUpdates);
            } else if (link.observation !== undefined) {
                this.updateObservationTuples(link.observation, dataId, tupleUpdates);
            } else if (link.predictor !== undefined) {
                this.updatePredictorTuples(link.predictor, dataId, tupleUpdates);
            } else if (link.dataType !== undefined) {
                this.updateDataTypeTuples(link.dataType, dataId, tupleUpdates);
            }
        }
    }
    //#endregion

    //#region Private Functions
    addAnswerLinks(question, answer, dataId, links) {
        function addLinks(location) {
            let value = answer[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    if (value[i] === dataId) {
                        if (!links.find((e) => e.answer === answer))
                            links.push({ question, answer, type: 'Answer', location });
                        break;
                    }
                }
            } else if (value === dataId) {
                if (!links.find((e) => e.answer === answer))
                    links.push({ question, answer, type: 'Answer', location });
            }
        }
        function addConditionLinks(location) {
            let value = answer[location];
            if (value) {
                //log.debug("addConditionLinks:", value );
                if (value.indexOf(`'${dataId}'`) >= 0) {
                    if (!links.find((e) => e.answer === answer)) {
                        links.push({ question, answer, type: 'Answer', location });
                    }
                }
            }
        }

        addLinks('dataId');
        addLinks('baselineId');
        addLinks('addOptionalData');
        addLinks('addRequiredData');
        addConditionLinks('preConditions');
    }

    addQuestionTextLinks(question, textId, links) {
        function addLink(location) {
            if (!links.find((e) => e.question === question))
                links.push({ question, type: 'Question', location });
        }

        if (question.explanation && question.explanation.indexOf(textId) >= 0) {
            addLink('explanation');
        }
        if (isArrayValid(question.question)) {
            question.question.forEach(text => {
                if (text.text && text.text.indexOf(textId) >= 0) {
                    addLink('question');
                }
            });
        }
        if (isArrayValid(question.answers)) {
            question.answers.forEach(answer => {
                if (answer.explanation && answer.explanation.indexOf(textId) >= 0) {
                    addLink('answer.explanation');
                }
                if (answer.category && answer.category.indexOf(textId) >= 0) {
                    addLink('answer.category');
                }
                for (let i = 0; i < answer.text.length; ++i) {
                    if (answer.text[i] && answer.text[i].indexOf(textId) >= 0) {
                        addLink("answer");
                    }
                }
            });
        }
    }

    addQuestionLinks(question, dataId, links) {
        function addLinks(location) {
            let value = question[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    if (value[i] === dataId) {
                        if (!links.find((e) => e.question === question))
                            links.push({ question, type: 'Question', location });
                        break;
                    }
                }
            }
            else if (value === dataId) {
                if (!links.find((e) => e.question === question))
                    links.push({ question, type: 'Question', location });
            }
        }
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((e) => e.question === question)) {
                    links.push({ question, type: 'Question', location });
                }
            }
        }

        addLinks('dataId');
        addLinks('baselineId');
        addLinks('addOptionalData');
        addLinks('addRequiredData');
        addConditionLinks(question, 'preConditions');
        if (isArrayValid(question.question)) {
            question.question.forEach(text => addConditionLinks(text, 'condition'));
        }
        if (isArrayValid(question.answers)) {
            question.answers.forEach(answer => this.addAnswerLinks(question, answer, dataId, links));
        }
    }

    addModelLinks(model, dataId, links) {
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((e) => e.model === model)) {
                    links.push({ model, type: 'Model', location });
                }
            }
        }
        function addLinks(location) {
            let value = model[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    addConditionLinks(value[i], 'condition');
                    if (value[i] === dataId || value[i].dataId === dataId) {
                        if (!links.find((e) => e.model === model))
                            links.push({ model, type: 'Model', location });
                        break;
                    }
                }
            } else if (value === dataId) {
                links.push({ model, type: 'Model', location });
            }
        }

        addLinks('dataId');
        addLinks('triggerSymptoms');
        addLinks('primarySymptoms');
        addLinks('associatedSymptoms');
        addLinks('positiveSymptoms');
        addLinks('negativeSymptoms');
        addLinks('laboratory');
        addLinks('clinicalModifiers');
        addLinks('requiredData');
    }

    addDetectLinks(detect, dataId, links) {
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((e) => e.detect === detect)) {
                    links.push({ detect, location });
                }
            }
        }
        function addLinks(location) {
            let value = detect[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    addConditionLinks(value[i], 'condition');
                    if (value[i] === dataId || value[i].dataId === dataId) {
                        if (!links.find((e) => e.detect === detect))
                            links.push({ detect, location });
                        break;
                    }
                }
            } else if (value === dataId) {
                links.push({ detect, location });
            }
        }

        addLinks('modelDataId');
        addLinks('alertDataId');
        addLinks('gradeDataId');
        addLinks('priorityDataId');
        addLinks('scoreDataId');
        addLinks('conditions');
    }

    addDataModelLinks(data, dataId, links) {
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((e) => e.data === data)) {
                    links.push({ data, location });
                }
            }
        }
        function addLinks(location) {
            let value = data[location];
            if (value === dataId) {
                if (!links.find((e) => e.data === data)) {
                    links.push({ data, location });
                }
            }
        }

        addLinks('dataId');
        addConditionLinks(data, 'condition');
        if (isArrayValid(data.values)) {
            data.values.forEach((value) => {
                addConditionLinks(value, 'condition');
                addConditionLinks(value, 'value');
            })
        }
    }

    addRecommendLinks(recommend, dataId, links) {
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((e) => e.recommend === recommend)) {
                    links.push({ recommend, location });
                }
            }
        }

        if (isArrayValid(recommend.groups)) {
            recommend.groups.forEach((group) => addConditionLinks(group, 'dataConditions'));
        }
    }

    addObservationLinks(observation, dataId, links) {
        function addLinks(location) {
            let value = observation[location];
            if (value === dataId) {
                if (!links.find((e) => e.observation === observation)) {
                    links.push({ observation, location });
                }
            }
        }

        addLinks('dataId');
    }

    addPredictorLinks(predictor, dataId, links) {
        function addLinks(location) {
            let value = predictor[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    if (value[i] === dataId || value[i].dataId === dataId) {
                        if (!links.find((e) => e.predictor === predictor))
                            links.push({ predictor, location });
                        break;
                    }
                }
            } else if (value.dataId === dataId) {
                links.push({ predictor, location });
            }
        }

        addLinks('target');
        addLinks('related');
        addLinks('meta');
    }

    addEducationLinks(education, dataId, links) {
        function addConditionLinks(obj, location) {
            let value = obj[location];
            if (value && value.indexOf(`'${dataId}'`) >= 0) {
                if (!links.find((v) => v.videoManifest === education)) {
                    links.push({ education, location });
                }
            }
        }
        addConditionLinks(education, 'condition');
    }

    addDataTypeLinks(dataType, dataId, links) {
        function addLinks(location) {
            let value = dataType[location];
            if (value === dataId) {
                links.push({ dataType, type: 'DataType', location });
            }
        }

        addLinks('dateId');
    }

    loadObservations() {
        const self = this;
        return new Promise((resolve) => {
            // firstly, flag all medications as loading..
            let { observations, observationsHash } = self.data.cache;
            if (!Array.isArray(observations)) observations = [];
            if (!observationsHash) observationsHash = {};
            if (self.loadingObservations !== true) {
                self.loadingObservations = true;
                for (let i = 0; i < observations.length; ++i) {
                    observations[i].loading = true;
                    observations[i].__index = i;
                }

                const BLOCK_SIZE = 5000;
                function blockLoad(opts, offset = 0) {
                    loadObservations(self.appContext, { ...opts, offset, limit: BLOCK_SIZE }).then((result) => {
                        let anotherBlock = result.length === BLOCK_SIZE;

                        for (let k = 0; k < result.length; ++k) {
                            let observation = result[k];
                            let previous = observationsHash[observation.ruleId];
                            if (previous) {
                                observation.__index = previous.__index;
                                observations[observation.__index] = observation;
                                observationsHash[observation.ruleId] = observation;
                            } else {
                                observation.__index = observations.length;
                                observations.push(observation);
                                observationsHash[observation.ruleId] = observation;
                            }
                        }

                        if (!anotherBlock) {
                            // remove any deleted medications
                            for (let k = 0; k < observations.length; ++k) {
                                let observation = observations[k];
                                if (observation.loading === true) {
                                    observations.splice(k--, 1);
                                    delete observationsHash[observation.ruleId];
                                }
                            }

                            self.loadingObservations = false;
                            log.debug("loadObservations done!");
                        } else {
                            blockLoad(opts, offset + BLOCK_SIZE);
                        }
                        PubSub.publish("STORE_UPDATE", self.data);
                    })
                }

                blockLoad({ dependencies: true });
            }
            resolve({ observations, observationsHash });
        })
    }

    loadMedications() {
        const self = this;
        return new Promise((resolve) => {
            // firstly, flag all medications as loading..
            let { medications, medicationsHash } = self.data.cache;
            if (!Array.isArray(medications)) medications = [];
            if (!medicationsHash) medicationsHash = {};
            if (self.loadingMedications !== true) {
                self.loadingMedications = true;
                for (let i = 0; i < medications.length; ++i) {
                    medications[i].loading = true;
                    medications[i].__index = i;
                }

                const BLOCK_SIZE = 5000;
                function blockLoad(opts, offset = 0) {
                    loadMedications(self.appContext, { ...opts, offset, limit: BLOCK_SIZE }).then((result) => {
                        let anotherBlock = result.length === BLOCK_SIZE;

                        for (let k = 0; k < result.length; ++k) {
                            let medication = result[k];
                            let previous = medicationsHash[medication.medicationId];
                            if (previous) {
                                medication.__index = previous.__index;
                                medications[medication.__index] = medication;
                                medicationsHash[medication.medicationId] = medication;
                            } else {
                                medication.__index = medications.length;
                                medications.push(medication);
                                medicationsHash[medication.medicationId] = medication;
                            }
                        }

                        if (!anotherBlock) {
                            // remove any deleted medications
                            for (let k = 0; k < medications.length; ++k) {
                                let medication = medications[k];
                                if (medication.loading === true) {
                                    medications.splice(k--, 1);
                                    delete medicationsHash[medication.medicationId];
                                }
                            }

                            self.loadingMedications = false;
                            log.debug("loadMedications done!");
                        } else {
                            blockLoad(opts, offset + BLOCK_SIZE);
                        }
                        PubSub.publish("STORE_UPDATE", self.data);
                    })
                }

                blockLoad({ dependencies: true });
            }
            resolve({ medications, medicationsHash });
        })
    }

    loadConditions() {
        const self = this;
        return new Promise((resolve) => {
            // firstly, flag all conditions as loading..
            let { conditions, conditionsHash } = self.data.cache;
            if (!Array.isArray(conditions)) conditions = [];
            if (!conditionsHash) conditionsHash = {};
            if (self.loadingConditions !== true) {
                self.loadingConditions = true;

                for (let i = 0; i < conditions.length; ++i) {
                    conditions[i].loading = true;
                    conditions[i].__index = i;
                }

                const BLOCK_SIZE = 10000;
                function blockLoad(opts, offset = 0) {
                    loadConditions(self.appContext, { ...opts, offset, limit: BLOCK_SIZE }).then((result) => {
                        let anotherBlock = result.length === BLOCK_SIZE;
                        for (let k = 0; k < result.length; ++k) {
                            let condition = result[k];
                            let previous = conditionsHash[condition.conditionId];
                            if (previous) {
                                condition.__index = previous.__index;
                                conditions[condition.__index] = condition;
                                conditionsHash[condition.conditionId] = condition;
                            } else {
                                condition.__index = conditions.length;
                                conditions.push(condition);
                                conditionsHash[condition.conditionId] = condition;
                            }
                        }

                        if (!anotherBlock) {
                            // remove any deleted conditions
                            for (let k = 0; k < conditions.length; ++k) {
                                let condition = conditions[k];
                                if (condition.loading === true) {
                                    conditions.splice(k--, 1);
                                    delete conditionsHash[condition.conditionId];
                                }
                            }
                            self.loadingConditions = false;
                            log.debug("loadConditions done!");
                        } else {
                            blockLoad(opts, offset + BLOCK_SIZE);
                        }
                        PubSub.publish("STORE_UPDATE", self.data);
                    })
                }

                blockLoad({ dependencies: true });
            }
            resolve({ conditions, conditionsHash });
        })
    }

    loadProcedures() {
        const self = this;
        return new Promise((resolve) => {
            // firstly, flag all procedures as loading..
            let { procedures, proceduresHash } = self.data.cache;
            if (!Array.isArray(procedures)) procedures = [];
            if (!proceduresHash) proceduresHash = {};
            if (self.loadingProcedures !== true) {
                self.loadingProcedures = true;
                for (let i = 0; i < procedures.length; ++i) {
                    procedures[i].loading = true;
                    procedures[i].__index = i;
                }

                const BLOCK_SIZE = 10000;
                function blockLoad(opts, offset = 0) {
                    loadProcedures(self.appContext, { ...opts, offset, limit: BLOCK_SIZE }).then((result) => {
                        let anotherBlock = result.length === BLOCK_SIZE;
                        for (let k = 0; k < result.length; ++k) {
                            let procedure = result[k];
                            let previous = proceduresHash[procedure.procedureId];
                            if (previous) {
                                procedure.__index = previous.__index;
                                procedures[procedure.__index] = procedure;
                                proceduresHash[procedure.procedureId] = procedure;
                            } else {
                                procedure.__index = procedures.length;
                                procedures.push(procedure);
                                proceduresHash[procedure.procedureId] = procedure;
                            }
                        }

                        if (!anotherBlock) {
                            // remove any deleted procedures
                            for (let k = 0; k < procedures.length; ++k) {
                                let procedure = procedures[k];
                                if (procedure.loading === true) {
                                    procedures.splice(k--, 1);
                                    delete proceduresHash[procedure.procedureId];
                                }
                            }
                            self.loadingProcedures = false;
                            log.debug("loadProcedures done!");
                        } else {
                            blockLoad(opts, offset + BLOCK_SIZE);
                        }
                        PubSub.publish("STORE_UPDATE", self.data);
                    })
                }

                blockLoad({ dependencies: true });
            }
            resolve({ procedures, proceduresHash });
        })
    }

    loadDataTypes() {
        this.progress = <CircularProgress size={20} />;
        return new Promise((resolve, reject) => {
            const { plan } = this.appContext.state;
            if (! plan || !plan.planId ) {
                console.warn("loadDataTypes: No plan selected!")
                return resolve();
            }
            if (isArrayValid(this.data.promises)) {
                this.data.promises.push({ resolve, reject });        // already loading, so just queue up this promise
            } else {
                PubSub.publish("STORE_UPDATE", this.data);

                this.data.promises = [{ resolve, reject }];
                Promise.all([
                    loadQuestions(this.appContext, { dependencies: true }),
                    loadModels(this.appContext, { dependencies: true }),
                    loadDetectModels(this.appContext, { dependencies: true }),
                    loadDataModels(this.appContext, { dependencies: true }),
                    loadRecommendationModels(this.appContext, { dependencies: true }),
                    loadTypes(this.appContext, { dependencies: true, typePath: 'recommend_categories' }),
                    loadTypes(this.appContext, { dependencies: true, typePath: 'recommend_group_types' }),
                    loadTypes(this.appContext, { dependencies: true, typePath: 'recommend_protocols' }),
                    loadRecommendations(this.appContext, { dependencies: true }),
                    loadPredictorModels(this.appContext, { dependencies: true }),
                    loadText(this.appContext, { dependencies: true }),
                    loadDataTypes(this.appContext, { dependencies: true }),
                    loadEducation(this.appContext, { dependencies: true }),
                    getAlertLevels(this.appContext, { dependencies: true }),
                    loadAlertTypes(this.appContext, { dependencies: true }),
                    this.loadObservations(),
                    this.loadMedications(),
                    this.loadConditions(),
                    this.loadProcedures()
                ]).then(([questions, models, detect, data, recommend, recommend_categories, recommend_group_types, 
                    recommend_protocols, recommend_types, predictors, texts, types, education, alert_levels, alert_types,
                    { observations, observationsHash }, 
                    { medications, medicationsHash }, 
                    { conditions, conditionsHash }, 
                    { procedures, proceduresHash }
                ]) => {
                    this.data.cache_time = Date.now();
                    this.data.cache = {
                        questions,
                        models,
                        detect,
                        data,
                        recommend,
                        recommend_categories,
                        recommend_group_types,
                        recommend_protocols,
                        recommend_types,
                        predictors,
                        texts,
                        types,
                        education,
                        alert_levels,
                        alert_types,
                        observations,
                        observationsHash,
                        medications,
                        medicationsHash,
                        conditions,
                        conditionsHash,
                        procedures,
                        proceduresHash
                    };
                    this.data.dataIds = {};
                    this.data.textIds = {};
                    this.data.types = {};
                    this.data.texts = {};
                    for (let i = 0; i < this.data.cache.types.length; ++i) {
                        let type = this.data.cache.types[i];
                        this.data.types[type.dataId] = type;
                    }
                    for (let i = 0; i < this.data.cache.texts.length; ++i) {
                        let text = this.data.cache.texts[i];
                        if (!this.data.texts[text.language]) this.data.texts[text.language] = {};
                        this.data.texts[text.language][text.textId] = text;
                    }
                    this.data.recommend_types = {};
                    for (let i = 0; i < this.data.cache.recommend_types.length; ++i) {
                        let type = this.data.cache.recommend_types[i];
                        this.data.recommend_types[type.recommendId] = type;
                    }
                    for (let i = 0; i < this.data.promises.length; ++i) {
                        this.data.promises[i].resolve(this.data.cache);
                    }
                    this.data.promises = [];
                    this.data.cacheReady = true;
                    this.progress = null;

                    // optionally, validate all the data..
                    if (this.enableValidate === true) {
                        this.validateData();
                        PubSub.publish('ERRORS_TOPIC', this.data.errors);
                    }
                    PubSub.publish('STORE_UPDATE', this.data);
                }).catch((err) => {
                    if (isArrayValid(this.data.promises)) {
                        for (let i = 0; i < this.data.promises.length; ++i) {
                            this.data.promises[i].reject(err);
                        }
                    }
                    this.data.promises = [];
                    this.progress = null;
                });
            }
        });
    }

    addError(category, primaryId, error) {
        //log.debug(`addError, category: ${category}, primaryId: ${primaryId}, error:`, error);
        let errors = this.getErrors(category);
        errors.count += 1;
        if (!Array.isArray(errors.errors[primaryId])) errors.errors[primaryId] = [];
        errors.errors[primaryId].push(error);
    }
    //#endregion

    //#region Validate Functions
    validateDataId(category, primaryId, dataId, tupleIndex = -1) {
        if (Array.isArray(dataId)) {
            for (let i = 0; i < dataId.length; ++i) {
                this.validateDataId(category, primaryId, dataId[i], tupleIndex);
            }
        }
        else if (dataId) {
            let type = this.data.types[dataId];
            if (type === undefined) {
                this.addError(category, primaryId, `Linked to missing data type: ${dataId}`);
            } else if (tupleIndex >= type.tupleDescriptions.length) {
                this.addError(category, primaryId, `Invalid tuple index of ${tupleIndex}: ${dataId}`);
            }
        }
    }

    validateCondition(category, primaryId, condition) {
        if (typeof condition === 'string' && condition) {
            let isDataFound = [...condition.matchAll(/isDataFound\(([^,]+),([^,]+)/g)];
            for (let i = 0; i < isDataFound.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isDataFound[i][2]);
                this.validateDataId(category, primaryId, dataId);
            }
            let isDataInRange = [...condition.matchAll(/isDataInRange\(([^,]+),([^,]+),([^,]+),/g)];
            for (let i = 0; i < isDataInRange.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isDataInRange[i][2]);
                // eslint-disable-next-line
                let tupleIndex = eval(isDataInRange[i][3]);
                this.validateDataId(category, primaryId, dataId, tupleIndex);
            }
            let isDataInRangeNumber = [...condition.matchAll(/isDataInRangeNumber\(([^,]+),([^,]+),([^,]+),([^,]+),/g)];
            for (let i = 0; i < isDataInRangeNumber.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isDataInRangeNumber[i][3]);
                // eslint-disable-next-line
                let tupleIndex = eval(isDataInRangeNumber[i][4]);
                this.validateDataId(category, primaryId, dataId, tupleIndex);
            }
            let isDataInRangeCount = [...condition.matchAll(/isDataInRangeCount\(([^,]+),([^,]+),([^,]+),/g)];
            for (let i = 0; i < isDataInRangeNumber.length; ++i) {
                // eslint-disable-next-line
                let array = eval(isDataInRangeCount[i][3]);
                for (let j = 0; j < array.length; ++j) {
                    this.validateDataId(category, primaryId, array[j].dataId, array[j].tupleIndex);
                }
            }
            let isDataSlope = [...condition.matchAll(/isDataSlope\(([^,]+),([^,]+),([^,]+),/g)];
            for (let i = 0; i < isDataSlope.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isDataSlope[i][2]);
                // eslint-disable-next-line
                let tupleIndex = eval(isDataSlope[i][3]);
                this.validateDataId(category, primaryId, dataId, tupleIndex);
            }
            let isNewWorseSymptoms = [...condition.matchAll(/isNewWorseSymptoms\(([^,]+),([^,]+)/g)];
            for (let i = 0; i < isNewWorseSymptoms.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isNewWorseSymptoms[i][2]);
                this.validateDataId(category, primaryId, dataId);
            }
            let isDataValue = [...condition.matchAll(/isDataValue\(([^,]+),([^,]+),([^,]+),/g)];
            for (let i = 0; i < isDataValue.length; ++i) {
                // eslint-disable-next-line
                let dataId = eval(isDataValue[i][2]);
                // eslint-disable-next-line
                let tupleIndex = eval(isDataValue[i][3]);
                this.validateDataId(category, primaryId, dataId, tupleIndex);
            }
        }
    }

    validateObservations(observation) {
        if (!observation.isReviewed) {
            this.addError('observations', observation.ruleId, "Observation needs to be reviewed.");
        }
        if ((observation.normalizedTupleIndex !== null && observation.normalizedTupleIndex === observation.tupleIndex)
            || (observation.tupleIndex !== null && observation.tupleIndex === observation.unitTupleIndex)
            || (observation.unitTupleIndex !== null && observation.unitTupleIndex === observation.normalizedTupleIndex)) {
            this.addError('observations', observation.ruleId, "Observation has conflicting tuple indexes.");
        }
        this.validateDataId('observations', observation.ruleId, observation.dataId);
    }

    validateDetectModel(detect) {
        const self = this;
        function checkDataId(dataId) {
            self.validateDataId(`${detect.category}_detect`, detect.modelId, dataId);
        }
        checkDataId(detect.alertDataId);
        checkDataId(detect.gradeDataId);
        checkDataId(detect.modelDataId);
        checkDataId(detect.priorityDataId);
        checkDataId(detect.scoreDataId);

        for (let i = 0; i < detect.conditions.length; ++i) {
            this.validateCondition(`${detect.category}_detect`, detect.modelId, detect.conditions[i].condition);
        }
    }

    validateModel(model) {
        const self = this;
        function checkDataId(dataId, tupleIndex = -1) {
            self.validateDataId(`${model.category}_models`, model.modelId, dataId, tupleIndex);
        }
        function checkCondition(condition) {
            self.validateCondition(`${model.category}_models`, model.modelId, condition);
        }
        function checkSymptoms(property) {
            const symptoms = model[property];
            if (Array.isArray(symptoms)) {
                for (let i = 0; i < symptoms.length; ++i) {
                    let symptom = symptoms[i];
                    checkDataId(symptom.dataId, symptom.tupleIndex);
                    checkCondition(symptom.condition);
                }
            }
            else {
                throw new Error(`${property} is not an array!`)
            }
        }
        try {
            checkDataId(model.dataId);
            checkSymptoms('triggerSymptoms');
            checkSymptoms('primarySymptoms');
            checkSymptoms('associatedSymptoms');
            checkSymptoms('clinicalModifiers');
            checkSymptoms('laboratory');
            checkSymptoms('negativeSymptoms');
            checkSymptoms('positiveSymptoms');
            checkDataId(model.requiredData);
        } catch (err) {
            console.error(`Caught error for model ${model.modelId}:`, err);
            throw err;
        }
        
        //ensure the compliant text is not empty.. 
        // if (!model.complaintText || model.complaintText == "")
        //     this.addError(`${model.category}_models`, model.modelId, "Complaint text missing");
    }

    validateQuestion(question) {
        const self = this;
        function checkDataId(dataId, tupleIndex = -1) {
            self.validateDataId(`questions`, question.questionId, dataId, tupleIndex);
        }
        function checkCondition(condition) {
            self.validateCondition('questions', question.questionId, condition);
        }
        checkDataId(question.dataId);
        checkDataId(question.baselineId);
        checkDataId(question.addOptionalData);
        checkDataId(question.addRequiredData);
        checkCondition(question.preConditions);

        if (isArrayValid(question.answers)) {
            for (let i = 0; i < question.answers.length; ++i) {
                checkDataId(question.answers[i].dataId);
                checkCondition(question.answers[i].preConditions);
            }
        }
    }

    validateDataModel(model) {
        this.validateCondition('data_models', model.modelId, model.condition);
        for (let i = 0; i < model.values.length; ++i) {
            this.validateCondition('data_models', model.modelId, model.values[i].condition);
        }
    }

    validateRecommendModel(model) {
        const self = this;
        function checkCondition(condition) {
            self.validateCondition('recommend_models', model.modelId, condition);
        }
        // let foundRecommendations = false;
        for (let i = 0; i < model.groups.length; ++i) {
            let group = model.groups[i];
            checkCondition(group.dataConditions);
            for (let j = 0; j < group.categories.length; ++j) {
                let category = group.categories[j];
                for (let k = 0; k < category.recommendations.length; ++k) {
                    // foundRecommendations = true;
                    let recommend = category.recommendations[k];
                    if (this.data.recommend_types[recommend.recommendId] === undefined) {
                        this.addError('recommend_models', model.modelId, `Recommentation ${group.name}/${category.name}/${recommend.name} [${recommend.recommendId}] missing.`);
                    }
                }
            }
        }
        // if (!Boolean(foundRecommendations)) {
        //     this.addError('recommend_models', model.modelId, `No recommendations defined`);
        // }
    }

    validatePredictorModel(model) {
        const self = this;
        function checkDataId(dataId, tupleIndex) {
            self.validateDataId('predictor_models', model.predictorId, dataId, tupleIndex);
        }

        checkDataId(model.target.dataId, model.target.tupleIndex);
        for (let i = 0; i < model.related.length; ++i) {
            checkDataId(model.related[i].dataId, model.related[i].tupleIndex);
        }
        for (let i = 0; i < model.meta.length; ++i) {
            checkDataId(model.meta[i].dataId, model.meta[i].tupleIndex);
        }
        if (model.training) {
            for (let i = 0; i < model.training.andFilters.length; ++i) {
                let filter = model.training.andFilters[i];
                checkDataId(filter.dataId, filter.tupleIndex);
            }
            for (let i = 0; i < model.training.orFilters.length; ++i) {
                let filter = model.training.orFilters[i];
                checkDataId(filter.dataId, filter.tupleIndex);
            }
        }
    }

    validateSymptomModel(category, modelId) {
        // this is a special function that certifies that a model is wired correctly. 
        // This currently applies to survey and symptom since they are simple enough to do this
        if (this.data.cacheReady) {
            let cache = this.data.cache;
            try {
                let model = cache.models.find(model => model.modelId === modelId);
                if (!model) {
                    this.addError(category, modelId, "Model is missing");
                } else {
                    let modelDataId = model.modelId;
                    if (!isArrayValid(model.primarySymptoms) || !model.primarySymptoms[0].dataId) {
                        this.addError(category, modelId, "Symptom is not defined");
                    } else {
                        let symptomDataType = model.primarySymptoms[0];
                        let question = cache.questions.find((question) => question.dataId === symptomDataType.dataId);
                        if (!question || !question.questionId) {
                            this.addError(category, modelId, "Severity question not found ");
                        }
                    }
                    if (!isArrayValid(model.triggerSymptoms) || !model.triggerSymptoms[0].dataId) {
                        this.addError(category, modelId, "Chief complaint is not specified");
                    }
                    // else {
                    //     let symptomDataType = model.triggerSymptoms[0];
                    //     let question = cache.questions.find((question) => {
                    //         if (question.dataId === symptomDataType.dataId) return true;
                    //         if (isArrayValid(question.answers)) {
                    //             for (let i = 0; i < question.answers.length; ++i) {
                    //                 if (question.answers[i].dataId === symptomDataType.dataId) return true;
                    //             }
                    //         }
                    //         return false;
                    //     });
                    //     if (!question || !question.questionId) {
                    //         this.addError(category, modelId, "Chief complaint question/answer missing");
                    //     }
                    // }

                    let modelDataType = cache.types.find((type) => type.dataId === model.dataId);
                    if (!modelDataType || !modelDataType.dataId) {
                        this.addError(category, modelId, "Model data type is missing");
                    }


                    let priorityDataId = null;
                    let detectModel = cache.detect.find(m => m.modelDataId === modelDataId);
                    if (detectModel) {
                        if (!detectModel.gradeDataId) {
                            this.addError(category, modelId, "Grade data id not defined on detect model ");
                        } else {
                            let type = this.data.types[detectModel.gradeDataId];
                            if (type === undefined) {
                                this.addError(category, modelId, `Linked to missing data type: ${detectModel.gradeDataId}`);
                            }
                        }

                        if (!detectModel.priorityDataId) {
                            this.addError(category, modelId, "Priority data id not defined on detect model ");
                        } else {
                            priorityDataId = detectModel.priorityDataId;
                            let type = this.data.types[detectModel.priorityDataId];
                            if (type === undefined) {
                                this.addError(category, modelId, `Linked to missing data type: ${detectModel.priorityDataId}`);
                            }
                        }

                        if (!detectModel.scoreDataId) {
                            this.addError(category, modelId, "Score data id not defined on detect model ");
                        } else {
                            let type = this.data.types[detectModel.scoreDataId];
                            if (type === undefined) {
                                this.addError(category, modelId, `Linked to missing data type: ${detectModel.scoreDataId}`);
                            }
                        }

                        if (!detectModel.alertDataId) {
                            this.addError(category, modelId, "Alert data id not defined on detect model ");
                        } else {
                            let type = this.data.types[detectModel.alertDataId];
                            if (type === undefined) {
                                this.addError(category, modelId, `Linked to missing data type: ${detectModel.alertDataId}`);
                            }
                        }
                    }
                    if (priorityDataId) {
                        let recommendModel = cache.recommend.find(nextModel => {
                            if (!nextModel.groups) nextModel.groups = [];
                            return nextModel.groups.find(group => {
                                let funct = parseCode(group.dataConditions);
                                if (funct && isArrayValid(funct.functions)) {
                                    let isDataInRange = funct.functions[0];
                                    if (isDataInRange && isArrayValid(isDataInRange.args))
                                        return isDataInRange.args[1].string === priorityDataId;
                                }
                                return false;
                            });

                        });
                        //reocommendations are not required.. but if present.. check the recommendations
                        if (recommendModel) {
                            const errors = this.getErrors(`recommend_models`).errors;
                            let modelErrors = errors[recommendModel.modelId];
                            if (isArrayValid(modelErrors))
                                this.addError(category, modelId, "Recomend model has errors");
                        }
                    }



                }
            } catch (err) {
                console.error(`Caught error for model ${modelId}:`, err);
                throw err;
            }
        }
    }

    validateSurveyModel(category, modelId) {
        // this is a special function that certifies that a model is wired correctly. 
        // This currently applies to survey and symptom since they are simple enough to do this
        if (this.data.cacheReady) {
            let cache = this.data.cache;
            try {
                let model = cache.models.find(model => model.modelId === modelId);
                if (!model) {
                    this.addError(category, modelId, "Model is missing");
                } else {
                    if (!isArrayValid(model.primarySymptoms)) {
                        this.addError(category, modelId, "No primary symptoms defined");
                    } else {
                        for (let index = 0; index < model.primarySymptoms.length; index++) {
                            let symptom = model.primarySymptoms[0];
                            if (!symptom || !symptom.dataId) {
                                this.addError(category, modelId, "Primary symptom has no dataId");
                            }
                        }
                    }

                }
            } catch (err) {
                console.error(`Caught error for model ${modelId}:`, err);
                throw err;
            }
        }
    }

    validateData() {
        this.data.errors = {
            observations: {
                count: 0,
                errors: {}
            },
            pro_detect: {
                count: 0,
                errors: {}
            },
            triage_detect: {
                count: 0,
                errors: {}
            },
            lab_detect: {
                count: 0,
                errors: {}
            },
            pro_models: {
                count: 0,
                errors: {}
            },
            triage_models: {
                count: 0,
                errors: {}
            },
            lab_models: {
                count: 0,
                errors: {}
            },
            survey_models: {
                count: 0,
                errors: {}
            },
            symptom_models: {
                count: 0,
                errors: {}
            },
            symptom_detect: {
                count: 0,
                errors: {}
            },
            questions: {
                count: 0,
                errors: {}
            },
            data_models: {
                count: 0,
                errors: {}
            },
            recommend_models: {
                count: 0,
                errors: {}
            },
            predictor_models: {
                count: 0,
                errors: {}
            }
        };

        let startTime = Date.now();
        if (this.data.cacheReady) {
            let cache = this.data.cache;
            try {
                cache.questions.forEach(question => this.validateQuestion(question));
                cache.detect.forEach(detect => this.validateDetectModel(detect));
                cache.observations.forEach(observation => this.validateObservations(observation));
                cache.data.forEach(data => this.validateDataModel(data));
                cache.recommend.forEach(model => this.validateRecommendModel(model));
                cache.predictors.forEach(model => this.validatePredictorModel(model));
                cache.models.forEach(model => {
                    if (model.category === 'symptom')
                        this.validateSymptomModel('symptom_models', model.modelId);
                    if (model.category === 'survey')
                        this.validateSurveyModel('survey_models', model.modelId);
                    this.validateModel(model);
                });
            } catch (err) {
                console.error("Caught Error:", err)
            }
        }

        let elapsed = Date.now() - startTime;
        log.debug("Detected Errors:", this.data.errors, `, ${elapsed} MS`);
    }
    //#endregion

    //#region Tuple Update Functions

    // This updates the condition string with the provided tupleUpdates, it's making an assumption that the tupleIndex
    // follows the dataId
    updateLogicCondition(condition, dataId, tupleUpdates) {
        if (typeof condition !== 'string') return condition;
        function replaceAt(str, index, len, replace) {
            return str.substring(0, index) + replace + str.substring(index + len);
        }

        //let matches = /(isDataInRange\(context,)(\'hepatitisModel\'),(\'8\')/gm
        let idString = `'${dataId}'`;
        let idIndex = condition.indexOf(idString);
        while (idIndex >= 0) {
            for (let k = 0; k < tupleUpdates.length; ++k) {
                let update = tupleUpdates[k];
                let oldString = `'${update.oldIndex}'`;
                let newString = `'${update.newIndex}'`;
                let tupleIndex = condition.indexOf(oldString, idIndex);
                if (tupleIndex === (idIndex + idString.length + 1)) {
                    condition = replaceAt(condition, tupleIndex, oldString.length, newString);
                    break;      // need to break once we find a match, don't keep searching
                }
            }

            idIndex = condition.indexOf(idString, idIndex + 1);
        }

        return condition;
    }

    updateDetectTuples(detect, dataId, tupleUpdates) {
        let saveDetect = false;
        if (isArrayValid(detect.conditions)) {
            for (let i = 0; i < detect.conditions.length; ++i) {
                let cond = detect.conditions[i];
                let condition = this.updateLogicCondition(cond.condition, dataId, tupleUpdates);
                if (cond.condition !== condition) {
                    log.debug("Old Condition:", cond.condition);
                    log.debug("New Condition:", condition);
                    detect.conditions[i].condition = condition;
                    saveDetect = true;
                }
            }
        }

        if (saveDetect) {
            saveDetectModels(this.appContext, detect, detect.planId).catch((err) => {
                console.error("updateDetectTuples error:", err);
            })
        }
    }

    updateModelTuples(model, dataId, tupleUpdates) {
        const self = this;
        let saveModel = false;
        function updateConditionLinks(obj, location) {
            let value = obj[location];
            if (value) {
                let newValue = self.updateLogicCondition(value, dataId, tupleUpdates);
                if (value !== newValue) {
                    obj[location] = newValue;
                    saveModel = true;
                }
            }
        }
        function updateLinks(location) {
            let value = model[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    updateConditionLinks(value[i], 'condition');
                    if (value[i].dataId !== dataId || value[i].tupleIndex === undefined) continue;
                    let update = tupleUpdates.find((e) => e.oldIndex === value[i].tupleIndex);
                    if (update) {
                        value[i].tupleIndex = update.newIndex;
                        saveModel = true;
                    }
                }
            }
        }

        updateLinks('triggerSymptoms');
        updateLinks('primarySymptoms');
        updateLinks('associatedSymptoms');
        updateLinks('positiveSymptoms');
        updateLinks('negativeSymptoms');
        updateLinks('laboratory');
        updateLinks('clinicalModifiers');

        if (saveModel) {
            saveModels(this.appContext, model, model.planId).catch((err) => {
                console.error("updateModelTuples error:", err);
            })
        }
    }

    updateQuestionTuples(question, dataId, tupleUpdates) {
        const self = this;
        let saveQuestion = false;

        function updateConditionLinks(obj, location) {
            let value = obj[location];
            if (value) {
                let newValue = self.updateLogicCondition(value, dataId, tupleUpdates);
                if (value !== newValue) {
                    obj[location] = newValue;
                    saveQuestion = true;
                }
            }
        }

        if (question.baselineId === dataId) {
            let update = tupleUpdates.find((e) => e.oldIndex === question.baselineTuple);
            if (update) {
                question.baselineTuple = update.newIndex;
                saveQuestion = true;
            }
        }
        updateConditionLinks(question, 'preConditions');
        if (isArrayValid(question.question)) {
            question.question.forEach(text => updateConditionLinks(text, 'condition'));
        }
        if (isArrayValid(question.answers)) {
            question.answers.forEach(answer => updateConditionLinks(answer, 'preConditions'));
        }

        if (saveQuestion) {
            this.saveQuestions(question, question.planId).catch((err) => {
                console.error("updateQuestionTuples error:", err);
            })
        }
    }

    updateDataModelTuples(data, dataId, tupleUpdates) {
        const self = this;

        let saveData = false;
        function updateConditionLinks(obj, location) {
            let value = obj[location];
            if (value) {
                let newValue = self.updateLogicCondition(value, dataId, tupleUpdates);
                if (value !== newValue) {
                    obj[location] = newValue;
                    saveData = true;
                }
            }
        }

        updateConditionLinks(data, 'condition');
        if (isArrayValid(data.values)) {
            data.values.forEach((value) => {
                updateConditionLinks(value, 'condition');
                updateConditionLinks(value, 'value');

                if (data.dataId === dataId) {
                    let update = tupleUpdates.find((e) => e.oldIndex === value.tupleIndex);
                    if (update) {
                        value.tupleIndex = update.newIndex;
                        saveData = true;
                    }
                }
            })
        }

        if (saveData) {
            saveDataModel(this.appContext, data, data.planId).catch((err) => {
                console.error("updateDataModelTuples error:", err);
            })
        }
    }

    updateRecommendTuples(recommend, dataId, tupleUpdates) {
        const self = this;
        let saveRecommend = false;

        function updateConditionLinks(obj, location) {
            let value = obj[location];
            if (value) {
                let newValue = self.updateLogicCondition(value, dataId, tupleUpdates);
                if (value !== newValue) {
                    obj[location] = newValue;
                    saveRecommend = true;
                }
            }
        }

        if (isArrayValid(recommend.groups)) {
            recommend.groups.forEach((group) => updateConditionLinks(group, 'dataConditions'));
        }

        if (saveRecommend) {
            saveRecommendtionModel(this.appContext, recommend, recommend.planId).catch((err) => {
                console.error("updateRecommendTuples error:", err);
            })
        }
    }

    updateObservationTuples(observation, dataId, tupleUpdates) {
        let save = false;

        function updateTuple(obj, location) {
            let value = obj[location];
            let update = tupleUpdates.find((e) => e.oldIndex === value);
            if (update) {
                obj[location] = update.newIndex;
                save = true;
            }
        }
        if (observation.dataId === dataId) {
            updateTuple(observation, 'tupleIndex');
            updateTuple(observation, 'normalizedTupleIndex');
            updateTuple(observation, "unitTupleIndex");
        }

        if (save) {
            saveObservation(this.appContext, observation, observation.planId).catch((err) => {
                console.error("updateObservationTuples error:", err);
            })
        }
    }

    updatePredictorTuples(predictor, dataId, tupleUpdates) {
        let savePredictor = false;
        function updateTuples(location) {
            let value = predictor[location];
            if (isArrayValid(value)) {
                for (let i = 0; i < value.length; ++i) {
                    if (value[i].dataId === dataId) {
                        let update = tupleUpdates.find((e) => e.oldIndex === value[i].tupleIndex)
                        if (update) {
                            value[i].tupleIndex = update.newIndex;
                            savePredictor = true;
                        }
                    }
                }
            }
        }

        updateTuples('target');
        updateTuples('related');
        updateTuples('meta');

        if (savePredictor) {
            savePredictorModel(this.appContext, predictor, predictor.planId).catch((err) => {
                console.error("updatePredictortuples error:", err);
            })
        }
    }

    updateDataTypeTuples(dataType, dataId, tupleUpdates) {
        let save = false;

        function updateTuple(obj, location) {
            let value = obj[location];
            let update = tupleUpdates.find((e) => e.oldIndex === value);
            if (update) {
                obj[location] = update.newIndex;
                save = true;
            }
        }
        if (dataType.dateId === dataId) {
            updateTuple(dataType, 'dateTupleIndex');
        }

        if (save) {
            this.saveDataType(dataType, dataType.planId).catch((err) => {
                console.error("updateDataTypeTuples error:", err);
            })
        }
    }
    //#endregion

    //#region CRUD Interface

    // help function to block until loadDataTypes() is done.
    blockOnUpdate() {
        return new Promise((resolve) => {
            if (this.progress !== null) {
                setTimeout(() => {
                    this.blockOnUpdate().then(resolve);
                }, 100);
            } else {
                resolve();
            }
        })
    }


    updateQuestion(q) {
        if (Array.isArray(q)) {
            q.forEach(q => this.updateQuestion(q));
        } else {
            let update = this.getQuestions().findIndex((k) => k.questionId === q.questionId);
            if (update >= 0)
                this.getQuestions()[update] = q;
            else
                this.getQuestions().push(q);
        }
    }

    saveQuestions(questions, planId = null) {
        return this.blockOnUpdate().then(() => saveQuestions(this.appContext, questions, planId)).then((result) => {
            this.updateQuestion(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        });
    }

    deleteQuestion(questionId) {
        return this.blockOnUpdate().then(() => deleteQuestion(this.appContext, { questionId })).then((result) => {
            let remove = this.getQuestions().findIndex((k) => k.questionId === questionId && k.override !== true);
            if (remove >= 0) {
                this.getQuestions().splice(remove, 1);
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateText(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateText(t));
        } else {
            this.getTextHash()[t.language][t.textId] = t;
            let update = this.getTexts().findIndex((k) => k.textId === t.textId && k.language === t.language);
            if (update >= 0)
                this.getTexts()[update] = t;
            else
                this.getTexts().push(t);
        }
    }

    saveText(text, planId = null) {
        return this.blockOnUpdate().then(() => saveText(this.appContext, text, planId)).then((result) => {
            this.updateText(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    translateText(textId, targetLanguageCode) {
        return this.blockOnUpdate().then(() => translateText(this.appContext, { textId, targetLanguageCode, async: false })).then((result) => {
            this.updateText(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteText(textId, language = null, planId = null) {
        return this.blockOnUpdate().then(() => deleteText(this.appContext, { textId, language, planId })).then((result) => {
            let remove = this.getTexts().findIndex((k) => k.textId === textId && (language === null || language === k.language) && k.override !== true);
            if (remove >= 0) {
                this.getTexts().splice(remove, 1);
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateDataType(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateDataType(t));
        } else {
            let update = this.getDataTypes().findIndex((k) => k.dataId === t.dataId);
            if (update >= 0)
                this.getDataTypes()[update] = t;
            else
                this.getDataTypes().push(t);
        }
    }

    saveDataType(type, planId = null) {
        return this.blockOnUpdate().then(() => saveDataType(this.appContext, type, planId)).then((result) => {
            this.updateDataType(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteDataType(dataId, planId = null) {
        return this.blockOnUpdate().then(() => deleteDataType(this.appContext, { dataId, planId })).then((result) => {
            let remove = this.getDataTypes().findIndex((k) => k.dataId === dataId && (!planId || k.planId === planId) && k.override !== true);
            if (remove >= 0) {
                this.getDataTypes().splice(remove, 1);
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateModel(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateModel(t));
        } else {
            let update = this.getModels().findIndex((k) => k.modelId === t.modelId);
            if (update >= 0)
                this.getModels()[update] = t;
            else
                this.getModels().push(t);
        }
    }

    saveModel(model, planId = null) {
        return this.blockOnUpdate().then(() => saveModels(this.appContext, model, planId)).then((result) => {
            this.updateModel(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteModel(modelId, planId = null) {
        return this.blockOnUpdate().then(() => deleteModels(this.appContext, { modelId, planId })).then((result) => {
            let remove = this.getModels().findIndex((k) => k.modelId === modelId && (!planId || k.planId === planId) && k.override !== true);
            if (remove >= 0) {
                this.getModels().splice(remove, 1);
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateMedication(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateMedication(t));
        } else {
            let update = this.getMedications().findIndex((k) => k.medicationId === t.medicationId);
            if (update >= 0)
                this.getMedications()[update] = t;
            else
                this.getMedications().push(t);
            this.getMedicationsHash()[t.medicationId] = t;
        }
    }

    saveMedication(type, planId = null) {
        return this.blockOnUpdate().then(() => saveMedication(this.appContext, type, planId)).then((result) => {
            this.updateMedication(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteMedication(medicationId, planId = null) {
        return this.blockOnUpdate().then(() => deleteMedication(this.appContext, { medicationId, planId })).then((result) => {
            let remove = this.getMedications().findIndex((k) => k.medicationId === medicationId && (planId === null || k.planId === planId) && k.override !== true);
            if (remove >= 0) {
                this.getMedications().splice(remove, 1);
                delete this.getMedicationsHash()[medicationId];
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateProcedure(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateProcedure(t));
        } else {
            let update = this.getProcedures().findIndex((k) => k.procedureId === t.procedureId);
            if (update >= 0)
                this.getProcedures()[update] = t;
            else
                this.getProcedures().push(t);
            this.getProceduresHash()[t.procedureId] = t;
        }
    }

    saveProcedure(procedure, planId) {
        return this.blockOnUpdate().then(() => saveProcedure(this.appContext, procedure, planId)).then((result) => {
            this.updateProcedure(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteProcedure(procedureId, planId = null) {
        return this.blockOnUpdate().then(() => deleteProcedure(this.appContext, { procedureId, planId })).then((result) => {
            let remove = this.getProcedures().findIndex((k) => k.procedureId === procedureId && (planId === null || planId === k.planId) && k.override !== true);
            if (remove >= 0) {
                this.getProcedures().splice(remove, 1);
                delete this.getProceduresHash()[procedureId];
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateCondition(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateCondition(t));
        } else {
            let update = this.getConditions().findIndex((k) => k.conditionId === t.conditionId);
            if (update >= 0)
                this.getConditions()[update] = t;
            else
                this.getConditions().push(t);
            this.getConditionsHash()[t.conditionId] = t;
        }
    }

    saveCondition(conditions, planId = null) {
        return this.blockOnUpdate().then(() => saveCondition(this.appContext, conditions, planId)).then((result) => {
            this.updateCondition(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteCondition(conditionId, planId) {
        return this.blockOnUpdate().then(() => deleteCondition(this.appContext, { conditionId, planId })).then((result) => {
            let remove = this.getConditions().findIndex((k) => k.conditionId === conditionId && (planId === null || planId === k.planId) && k.override !== true);
            if (remove >= 0) {
                this.getConditions().splice(remove, 1);
                delete this.getConditionsHash()[conditionId];
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }

    updateObservationRule(t) {
        if (Array.isArray(t)) {
            t.forEach(t => this.updateObservationRule(t));
        } else {
            let update = this.getObservationRules().findIndex((k) => k.ruleId === t.ruleId);
            if (update >= 0)
                this.getObservationRules()[update] = t;
            else
                this.getObservationRules().push(t);
            //this.getConditionsHash()[t.conditionId] = t;
        }
    }

    saveObservationRule(rules, planId = null) {
        return this.blockOnUpdate().then(() => saveObservation(this.appContext, rules, planId)).then((result) => {
            this.updateObservationRule(result);
            PubSub.publish('STORE_UPDATE', this.data);
            return result;
        })
    }

    deleteObservationRule(ruleId, planId) {
        return this.blockOnUpdate().then(() => deleteObservation(this.appContext, { ruleId, planId })).then((result) => {
            let remove = this.getObservationRules().findIndex((k) => k.ruleId === ruleId && (planId === null || planId === k.planId) && k.override !== true);
            if (remove >= 0) {
                this.getObservationRules().splice(remove, 1);
                //delete this.getConditionsHash()[conditionId];
                PubSub.publish('STORE_UPDATE', this.data);
            }
            return result;
        })
    }
    //#endregion
}

export default DataTypesStore;
